import { render, staticRenderFns } from "./InputForm.vue?vue&type=template&id=c6064094&scoped=true"
import script from "./InputForm.vue?vue&type=script&lang=js"
export * from "./InputForm.vue?vue&type=script&lang=js"
import style0 from "./InputForm.vue?vue&type=style&index=0&id=c6064094&prod&scoped=true&lang=css"
import style1 from "./InputForm.vue?vue&type=style&index=1&id=c6064094&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6064094",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VFadeTransition,VIcon,VSlider,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
