import { render, staticRenderFns } from "./CardDivider.vue?vue&type=template&id=6266822e&scoped=true"
import script from "./CardDivider.vue?vue&type=script&lang=js"
export * from "./CardDivider.vue?vue&type=script&lang=js"
import style0 from "./CardDivider.vue?vue&type=style&index=0&id=6266822e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6266822e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
