import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'e4641fb1-fb60-46a1-9450-7cde55cedbe9',
  clientToken: 'pub8203b55acfd4e1bfcf426929c02f1dd6',
  site: 'datadoghq.com',
  service: 'bifi',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
