import { render, staticRenderFns } from "./CoinStatus.vue?vue&type=template&id=445ecb20&scoped=true"
import script from "./CoinStatus.vue?vue&type=script&lang=js"
export * from "./CoinStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445ecb20",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VLayout})
